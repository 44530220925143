import CustomerAPI from '@/api/customer';
import StoreApi from '@/api/store';

const initializeState = () => {
    return {
        error: null,
        qrData: null,
        stores: [],
    };
};

const state = initializeState();

const mutations = {
    // from api
    setQRData(state, data){
        state.qrData = data;
        state.error = null;
    },
    setError(state, data) {
        state.error = data;
        state.qrData = null;
    },
    setStores(state, data) {
        state.stores = data;
        state.error = null;
    },
};

const getters = {
    qrData: state => state.qrData,
    error: state => state.error,
    stores: state => state.stores,
};

const actions = {
    generateQR({ commit }, payload) {
        return CustomerAPI.generateQR(payload)
            .then(({ data }) => {
                if (data?.qr_str?.length > 0) {
                    commit('setQRData', data.qr_str);
                }
            })
            .catch(error => {
                if(error.response.data?.msg){
                    commit('setError', error.response.data?.msg);
                }
                else {
                    if (error.response.status === 400){
                        console.log("abasdasd");
                        throw new Error("dataInvalid")
                    } 
                    else {
                        throw new Error("serverError")
                    }
                }
            });
    },
    async getUserStore({ commit}){
        await StoreApi.stores()
            .then(({ data }) => {
                if (data?.stores?.length > 0) {
                    commit('setStores', data.stores);
                }
            }) 
            .catch(error => {
                commit('setError', error.response.data.msg);
            });
        }
};

export const createQR ={
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};