import Vue from "vue"
import Vuex from "vuex"

import { auth } from "./auth.module"
import { store } from "./store.module"
import { screen } from "./screen.module"
import { locale } from "./locale.module"
import { customer } from "./customer.module"
import { promotion } from "./promotion.module"
import { createQR } from "./createqr.module"
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			paths: ["auth", "locale"],
		}),
	],
	modules: {
		auth,
		store,
		screen,
		locale,
		customer,
		promotion,
		createQR
	},
})
