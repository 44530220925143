import Vue from "vue"
import Vuex from 'vuex';
import App from "./App.vue"
import router from "./router"
import i18n, {loadHotModule} from "./i18n"
import store from "./store"
import moment from "moment";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import "@/assets/css/main.scss"
import '@/assets/css/font-awesome.css';
import vuetify from "./plugins/vuetify"


Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuex);
Vue.config.productionTip = false

new Vue({
	router,
	i18n,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app")

loadHotModule();

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.mixin({
    methods: {
        setErrorImg(e)
        {
            e.target.src = require('@/assets/images/404.png')
        },
    },
});
Vue.filter('regularDateTime', function(value) {
    return moment(value).format("YYYY, MMM DD [|] hh:mm")
})
Vue.filter( "formatCurrency", function(value) {
    if (value === null || value === '') {
        return '0 đ';
    }
    let result = String(value).includes('.')
    if(result)
    {
        const strAmount = String(value).split(".");
        value = strAmount[0];

    }
    return String(value).replace(/(.)(?=(\d{3})+$)/g, "$1,") + '₫';
})